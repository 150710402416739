export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            // 'APPLICATIONS': 'Applications',
            // 'SAMPLE': {
            //     'TITLE': 'Sample',
            //     'BADGE': '25'
            // },
            'DASHBOARDS': 'Dashboards',
            'ADMINISTRATIVE': 'Administrative',
            'HEALTHUNITS': {
                'TITLE': 'Health Units',
                'BADGE': '25'
            },
            'PROFESSIONALS': {
                'TITLE': 'Professionals',
                'BADGE': '25'
            },
            'PATIENTS': {
                'TITLE': 'Patients',
                'BADGE': '25'
            },
            'APPOINTMENTS': {
                'TITLE': 'Appointments',
                'BADGE': '25'
            },
            'APPOINTMENTS-LIST': {
                'TITLE': 'Health Appointments List',
                'BADGE': '25'
            },
            'APPOINTMENTS-NEW': {
                'TITLE': 'New Health Appointment',
                'BADGE': '25'
            }
        }
    }
};
