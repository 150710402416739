import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decoded, { JwtPayload, JwtDecodeOptions } from 'jwt-decode';

import { environment } from 'environments/environment';
import { UserService } from './user.service';

interface PropsJwt extends JwtPayload {
  role: string;
  accessToken: string;
}

interface User extends PropsJwt { 
  nameid: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly base_url = environment.baseUrl;

  private currentUserSubject: BehaviorSubject<User>;
  
  private userSubject = new BehaviorSubject<any>(null);
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient, 
    private _userService: UserService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')) || JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getUser() {
    return this.userSubject.asObservable();
  }

  // TODO: Form authentication
  login(email: string, senha: string, rememberMeChecked: boolean) {

    // TODO: Route authenticate
    return this.http.post<any>(`${this.base_url}/account/login`, { email, senha })
      .pipe(map(user => {
        if (user && user.accessToken) {
          let decodeUser = jwt_decoded<User>(user.accessToken);
          decodeUser.accessToken = user.accessToken;

          if (rememberMeChecked) {
            localStorage.setItem('currentUser', JSON.stringify(decodeUser));
          } else {
            sessionStorage.setItem('currentUser', JSON.stringify(decodeUser));
          }

          this.userSubject.next(decodeUser);
          this.currentUserSubject.next(decodeUser);
        }

        return user;
      }));
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
