import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  // MENU ADMINISTRATIVO
  {
    id: 'administrative',
    title: 'Administrativo',
    translate: 'NAV.ADMINISTRATIVE',
    type: 'group',
    children: [
      {
        id: 'healthunits',
        title: 'Unidades de Saúde',
        translate: 'NAV.HEALTHUNITS.TITLE',
        type: 'item',
        icon: 'account_balance',
        url: '/apps/healthunits',
      },
      {
        id: 'professionals',
        title: 'Profissionais de Saúde',
        translate: 'NAV.PROFESSIONALS.TITLE',
        type: 'item',
        icon: 'people',
        url: '/apps/professionals',
      },
      {
        id: 'patients',
        title: 'Pacientes',
        translate: 'NAV.PATIENTS.TITLE',
        type: 'item',
        icon: 'contacts',
        url: '/apps/patients',
      },
      {
        id: 'appointments',
        title: 'Atendimentos',
        translate: 'NAV.APPOINTMENTS',
        type: 'collapsable',
        children: [
          {
            id: 'appointments-new',
            title: 'Novo Atendimento',
            translate: 'NAV.APPOINTMENTS_NEW',
            type: 'item',
            url: '/apps/appointments/new',
            icon: 'contacts',
            exactMatch: true
          },
          {
            id: 'appointments-list',
            title: 'Lista de Atendimentos',
            translate: 'NAV.APPOINTMENTS_LIST',
            type: 'item',
            url: '/apps/appointments',
            icon: 'contacts',
            exactMatch: true
          }
        ]
      }
    ]
  }
];
