import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

// Login
import { User } from 'app/models/user';
import { AuthenticationService } from 'app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

@Component({
  selector     : 'login',
  templateUrl  : './login.component.html',
  styleUrls    : ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class LoginComponent implements OnInit {
  currentUser: User;
  loginForm: FormGroup;

  // Login 
  submitted = false;
  returnUrl: string;

  /**
* Constructor
*
* @param {FuseConfigService} _fuseConfigService
* @param {FormBuilder} _formBuilder
*/
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
  )
{
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/dashboard']);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
* On init
*/
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email   : ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: [false],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    this.authenticationService.login(
      this.f.email.value, 
      this.f.password.value,
      this.f.rememberMe.value,
    )
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          const horizontalPosition: MatSnackBarHorizontalPosition = 'end';
          const verticalPosition: MatSnackBarVerticalPosition = 'top';

          this._snackBar.openFromComponent(SnackBarComponent, {
            data: { msg: 'Usuário ou senha inválido' },
            panelClass: ['error-snack'],
            duration: 5000,
            horizontalPosition,
            verticalPosition
          });
        });
  }
}
