export const locale = {
    lang: 'pt-br',
    data: {
        'NAV': {
            'DASHBOARDS': 'Dashboards',
            'ADMINISTRATIVE': 'Administrativo',
            'APPOINTMENTS': 'Atendimentos de Saúde',
            'APPOINTMENTS_LIST': 'Lista de Atendimentos',
            'APPOINTMENTS_NEW': 'Novo Antendimento',
            'HEALTHUNITS': {
                'TITLE': 'Unidades de Saúde',
                'BADGE': '25'
            },
            'PROFESSIONALS': {
                'TITLE': 'Profissionais de Saúde',
                'BADGE': '25'
            },
            'PATIENTS': {
                'TITLE': 'Pacientes',
                'BADGE': '25'
            },
            // 'APPOINTMENTS': {
            //     'TITLE': 'Atendimentos de Saúde',
            //     'BADGE': '25'
            // },
            // 'APPOINTMENTS-LIST': {
            //     'TITLE': 'Lista de Atendimentos',
            //     'BADGE': '25'
            // },
            // 'APPOINTMENTS-NEW': {
            //     'TITLE': 'Novo Atendimento',
            //     'BADGE': '25'
            // },
        }
    }
};
