import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

export interface User {
  email   : string; 
  id      : string;
  roles   : any[];
  userName: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  resolver(userId: string): Observable<User>{
    return this.http
      .get<User>(`${this.baseUrl}/account/users/${userId}`);
  }
}
