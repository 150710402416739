import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isValidToken = false;
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      const { exp } = currentUser;
      isValidToken = this.validateTokenExpirationTime(exp);
    }
    
    if (!isValidToken) {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
    }
    
    return isValidToken;
  }

  private validateTokenExpirationTime(exp: number): boolean {
    const currentDate = Date.now();
    const expDate = exp * 1000;

    if (currentDate >= expDate) {
      return false;
    }

    return true;
  }
}
