import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { FakeDbService } from './fake-db/fake-db.service';

// Login
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { LoginModule } from 'app/main/pages/authentication/login/login.module';
import { AuthGuard } from 'app/helpers/auth.guard';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

const appRoutes: Routes = [
  // {
  //     path: '**',
  //     redirectTo: 'sample'
  // },
  {
    path: 'login',
    loadChildren: './main/pages/authentication/login/login.module#LoginModule',
  },
  {
    path: 'apps',
    loadChildren: './main/apps/apps.module#AppsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: './main/pages/pages.module#PagesModule',
    // canActivate: [AuthGuard]
  },
  {
    path: 'ui',
    loadChildren: './main/ui/ui.module#UIModule',
    // canActivate: [AuthGuard]
  },
  {
    path: '**',
   // redirectTo: 'apps/dashboards/analytics',
    loadChildren: './main/pages/home/home.module#HomeModule',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),

    // Fake Server - Modo DEV
    // InMemoryWebApiModule.forRoot(FakeDbService, {
    //     delay: 0,
    //     passThruUnknownUrl: true
    // }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    SampleModule,

    // Login
    LoginModule,
  ],
  providers: [
    AuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
